import React, { useState, useEffect } from "react";
// import SectionTitle from "@/components/section-title";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/css/bundle";
import MatchesCard from "@/components/cards/matches";
import { Link } from "gatsby";
import api from "@/axios";
import AOS from "aos";
import "aos/dist/aos.css";

import SwiperCore, { Autoplay, Pagination } from "swiper";

SwiperCore.use([Pagination]);

const NewsCarousel = () => {
  const carouselOptions = {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    navigation: {
      nextEl: "#news-carousel-next",
      prevEl: "#news-carousel-prev",
    },
    breakpoints: {
      0: {
        spaceBetween: 5,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 20,
        slidesPerView: 2.5,
        slidesPerGroup: 1,
      },
      992: {
        spaceBetween: 20,
        slidesPerView: 4.5,
        slidesPerGroup: 1,
      },
      1900: {
        spaceBetween: 20,
        slidesPerView: 6.5,
        slidesPerGroup: 1,
      },
    },
  };

  const [apiData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/news/o/get`, {
          params: { limit: 12, sponsor: true, highlighted: "yes", newResponse: true },
        });
        setData(response.data.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className="news">
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "350px" }}
        >
          <span className="loader"></span>
        </div>
      )}
      {!loading && (
        <Swiper className="news_slider" data-aos="fade-up" {...carouselOptions}>
          {error && (
            <div className="text-white mb-5 pt-4 container">{`Wystąpił problem z wczytaniem aktualności - ${error}`}</div>
          )}
          {!loading &&
            apiData &&
            /* apiData.map(({ id, title }) => ( */
            apiData.map((post, index) => (
              <SwiperSlide key={index}>
                <MatchesCard data={post} />
              </SwiperSlide>
            ))}

          <Container fluid className="slider-pag wrap swiper-margin-top pe-0">
            <Row className="justify-content-end d-flex align-items-center">
              <Col
                lg={1}
                md={3}
                sm={2}
                className="col-3 offset-lg-1 button-prev-col"
              >
                <div className="button-prev" id="news-carousel-prev"></div>
              </Col>
              <Col lg={1} md={3} sm={2} className="col-3 button-next-col">
                <div className="button-next" id="news-carousel-next"></div>
              </Col>
              <Col lg={9} md={6} sm={8} className="col-12 text-end">
                <Link to="/aktualnosci">
                  <div className="button-more common_btn red_bg">
                    <span>
                      Wszystkie aktualności
                      <div className="button-next d-inline-block ms-3"></div>
                    </span>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </Swiper>
      )}
    </section>
  );
};

export default NewsCarousel;
