import React from "react";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/pl";

import ekstraklasaLogo from "@/images/leagues/ekstraklasa-white.png";
import friendlyMatchLogo from "@/images/leagues/friendly-match-black.png";
import polishCupLogo from "@/images/leagues/polish-cup.png";
import ChampionsLeagueLogo from "@/images/leagues/Logo_UEFA_Champions_League.png";
import EuropeLeagueLogo from "@/images/leagues/Europa_League_2021.png";
import ConferenceLeagueLogo from "@/images/leagues/uefa-europa-conference-league.png";

const TicketsCard = ({ data, i }) => {
  const {
    startDate,
    week,
    leagueId,
    homeConShortName,
    awayConShortName,
    leagueName,
    homeConImage,
    awayConImage,
  } = data;
  const images = require.context("@/images/tickets/cards", true);
  const loadImage = (imageName) => images(`${imageName}`).default;

  const getImage = (cardIndex) => {
    const imagesCount = images.keys().length;
    const imageIndex = cardIndex % imagesCount;
    return `url('${loadImage(images.keys()[imageIndex])}')`;
  };

  const time = moment(startDate).format("HH:mm");
  return (
    <div className="singleTM tickets-card">
      <div
        className="tm_img tickets-card-bg"
        style={{
          backgroundImage: getImage(i),
          backgroundPosition: "center",
        }}
      >
        <div className="tm_overlay"></div>
        <div className="detail_TM">
          {[0, 2, 3].includes(leagueId) && (
            <>
              <img className="tickets-league-logo" src={ekstraklasaLogo} />
              <h5 className="tickets-league-type m-4">{week}. kolejka</h5>
            </>
          )}
          {leagueId === 4 && (
            <>
              <img className="tickets-league-logo" src={ChampionsLeagueLogo} />
              <h5 className="tickets-league-type m-4">{week}</h5>
            </>
          )}
          {leagueId === 5 && (
            <>
              <img className="tickets-league-logo" src={EuropeLeagueLogo} />
              <h5 className="tickets-league-type m-4">{week}</h5>
            </>
          )}
          {leagueId === 6 && (
            <>
              <img className="tickets-league-logo" src={ConferenceLeagueLogo} />
              <h5 className="tickets-league-type m-4">{week}</h5>
            </>
          )}
          {leagueId === 7 && (
            <>
              <img className="tickets-league-logo" src={friendlyMatchLogo} />
              <h5 className="tickets-league-type m-4">Mecz towarzyski</h5>
            </>
          )}
          {leagueId === 1 && (
            <>
              <img className="tickets-league-logo" src={polishCupLogo} />
              <h5 className="tickets-league-type m-4">{week}</h5>
            </>
          )}
          <span className="tickets-card-day text-white mb-2 text-uppercase">
            <Moment date={startDate} format="dddd"></Moment>
          </span>
          <span className="tickets-card-date text-white fw-bold mb-2">
            <Moment date={startDate} format="DD.MM.YYYY"></Moment>
          </span>
          <span className="tickets-card-time text-white fw-bold">
            {time != "00:00" ? time : "\u00a0\u00a0"}
          </span>
          <div className="tickets-team-logos text-center mb-2">
            <img
              src={
                homeConImage
                  ? `${process.env.GATSBY_API_BASE_URL}/${homeConImage.path}`
                  : ""
              }
            />
            <img
              src={
                awayConImage
                  ? `${process.env.GATSBY_API_BASE_URL}/${awayConImage.path}`
                  : ""
              }
            />
          </div>
          <span className="tickets-team-home text-center fw-bold text-uppercase">
            {homeConShortName}
          </span>
          <span className="tickets-team-away text-center text-white fw-bold text-uppercase">
            {awayConShortName}
          </span>
          <a
            href="https://bilety.jagiellonia.pl/"
            target="_blank"
            className="common_btn jag_bg mt-3"
          >
            <span>KUP BILET</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TicketsCard;
