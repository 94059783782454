import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "swiper/css/bundle";

const AdPreFooter = ({ homeApiData, loadingApi }) => {
  const isWindow = typeof window !== "undefined";
  const [windowSize, setWindowSize] = useState([
    isWindow ? window.innerWidth : 0,
    isWindow ? window.innerHeight : 0,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([
        isWindow ? window.innerWidth : 0,
        isWindow ? window.innerHeight : 0,
      ]);
    };

    if (isWindow) {
      window.addEventListener("resize", handleWindowResize);
    }

    return () => {
      if (isWindow) {
        window.removeEventListener("resize", handleWindowResize);
      }
    };
  }, [isWindow]);

  const scale = (windowSize[0] > 1760 ? 1660 : windowSize[0] - 120) / 2500;
  const heightResp = `${677 * scale}px`;

  const imageUrl = `${process.env.GATSBY_API_BASE_URL}/${homeApiData?.sectionImage?.path}`;

  return loadingApi ? (
    <div className="d-flex justify-content-center align-items-center loader-ad-pre-footer">
      <span className="loader" />
    </div>
  ) : (
    homeApiData && homeApiData.hide_section === false && (
      <section
        className="commonSection ad-pre-footer"
        style={{ paddingTop: 0 }}
      >
        <Container>
          <a
            href={homeApiData.section_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              style={{
                display: "inline-block",
                width: "100%",
                height: heightResp,
                padding: 0,
                overflow: "hidden",
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
          </a>
        </Container>
      </section>
    )
  );
};

export default AdPreFooter;
