import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/css/bundle";

const SponsorsListing = ({ homeApiData, loadingApi }) => {
  return loadingApi ? (
    <div className="d-flex justify-content-center align-items-center loader-sponsors-listing">
      <span className="loader"></span>
    </div>
  ) : (
    <>
      {homeApiData && homeApiData.hide_section === false && (
        <section className="commonSection sponsors-listing pt-0">
          <div
            className="single-news-content"
            dangerouslySetInnerHTML={{ __html: homeApiData.content }}
          ></div>
        </section>
      )}
    </>
  );
};

export default SponsorsListing;
