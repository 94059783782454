import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/css/bundle";
import { TvData, TvPosts, TvPostsFeatured } from "@/data";
import TvCard from "@/components/cards/tv";
import { Link } from "gatsby";
import api from "@/axios";

const Tv = () => {
  const [apiDataHighlighted, setData] = useState(null);
  const [apiData, setFullData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/movies/get`);
        let resData = response.data;
        setData(resData.filter(v => v.highlighted === 1));
        setFullData(resData.filter(v => v.highlighted === 0));
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
        setFullData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const { title } = TvData;
  const { posts } = TvPosts;
  const { postsFeatured } = TvPostsFeatured;

  const carouselOptions = {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    autoHeight: true,
    navigation: {
      nextEl: "#tv-next",
      prevEl: "#tv-prev",
    },
    breakpoints: {
      0: {
        spaceBetween: 5,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 30,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 3.5,
        slidesPerGroup: 1,
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 4.5,
        slidesPerGroup: 1,
      },
    },
  };

  return loading ? (
    <div className="d-flex justify-content-center align-items-center loader-tv">
      <span className="loader"></span>
    </div>
  ) : (
    <section className="commonSection tv">
      <Container>
        <Row>
          <Col lg={12} sm={12} className="text-start">
            <h2 className="text-white">{title}</h2>
          </Col>
        </Row>
        <Row className="mb-5 tv-cards-premium-row">
          {loading && <span className="loader"></span>}
          {error && (
            <div className="text-dark">{`Wystąpił problem z wczytaniem filmów - ${error}`}</div>
          )}
          {!loading &&
            apiDataHighlighted &&
            apiDataHighlighted.map(
              (post, index) =>
                index <= 1 && (
                  <Col lg={6} md={6} sm={12} className="col-12" key={index}>
                    <TvCard data={post} highlighted={1} />
                  </Col>
                )
            )}
        </Row>
        <Swiper className="tickets_slider" {...carouselOptions}>
          {error && (
            <div className="text-dark">{`Wystąpił problem z wczytaniem filmów - ${error}`}</div>
          )}
          {!loading &&
            apiData &&
            apiData.map(
              (post, index) =>
                index <= 9 && (
                  <SwiperSlide key={index}>
                    <TvCard data={post} />
                  </SwiperSlide>
                )
            )}
          <Container fluid className="slider-pag wrap swiper-margin-top pe-0">
            <Row className="justify-content-start d-flex align-items-center">
              <Col lg={1} md={3} sm={2} className="col-3 button-prev-col">
                <div className="button-prev" id="tv-prev"></div>
              </Col>
              <Col lg={1} md={3} sm={2} className="col-3 button-next-col">
                <div className="button-next" id="tv-next"></div>
              </Col>
              <Col lg={10} md={6} sm={8} className="col-12 text-end">
                <Link to="/jagatv/">
                  <div className="button-more common_btn red_bg">
                    <span>
                      PRZEJDŹ DO MATERIAŁÓW TV
                      <div className="button-next d-inline-block ms-3"></div>
                    </span>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </Swiper>
      </Container>
    </section>
  );
};

export default Tv;
