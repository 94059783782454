import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/css/bundle";
import { NewsBlockData } from "@/data";
import MatchesCard from "@/components/cards/matches";
import { Link } from "gatsby";
import api from "@/axios";

const NewsBlock = () => {
  const { title } = NewsBlockData;

  const [apiData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/news/o/get`, {
          params: { limit: 12, highlighted: "no", newResponse: true },
        });
        setData(response.data.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return loading ? (
    <div className="d-flex justify-content-center align-items-center loader-news-block">
      <span className="loader"></span>
    </div>
  ) : (
    <section className="commonSection">
      <Container>
        <Row>
          <Col lg={12} sm={12} className="text-start">
            <h2>{title}</h2>
          </Col>
        </Row>
        <Row>
          {error && (
            <div className="text-dark">{`Wystąpił problem z wczytaniem aktualności - ${error}`}</div>
          )}
          {!loading &&
            apiData &&
            apiData.map((post, index) => (
              <Col
                xl={2}
                lg={3}
                md={6}
                sm={6}
                className="col-12 mb-5"
                key={index}
              >
                <MatchesCard data={post} />
              </Col>
            ))}
        </Row>
        <Row>
          <Col className="col-12 text-end">
            <Link to="/aktualnosci">
              <div className="button-more common_btn red_bg">
                <span>
                  WSZYSTKIE AKTUALNOŚCI
                  <div className="button-next d-inline-block ms-3"></div>
                </span>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NewsBlock;
