import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/css/bundle";
import OurMatchesCard from "@/components/cards/our-matches";
import api from "@/axios";

const OurMatches = ({ homeApiData, loadingApi }) => {
  const [apiFullData, setFullData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        // const response = await api.get(`/stats/timetable`, {
        //   params: { mainTeam: true },
        // });
        const response = await api.get(`/matchroom/all`, {
          params: { mainTeam: true },
        });
        const resData = response.data;
        // resData = resData.filter(v => v.matchStatus === "Played");
        setFullData(resData);
        setError(null);
      } catch (err) {
        setError(err.message);
        setFullData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const carouselOptions = {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    autoHeight: true,
    navigation: {
      nextEl: "#our-matches-next",
      prevEl: "#our-matches-prev",
    },
    breakpoints: {
      0: {
        spaceBetween: 5,
        slidesPerView: 1,
        slidesPerGroup: 1,
        /* centeredSlides: true, */
      },
      576: {
        spaceBetween: 30,
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 2.2,
        slidesPerGroup: 1,
      },
      1700: {
        spaceBetween: 30,
        slidesPerView: 2.2,
        slidesPerGroup: 1,
      },
    },
  };

  return loadingApi ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "720px" }}
    >
      <span className="loader" />
    </div>
  ) : (
    homeApiData && homeApiData.hide_section === false && (
      <section
        className="commonSection our-matches"
        style={{
          backgroundImage: `url('${
            homeApiData.sectionImage
              ? `${process.env.GATSBY_API_BASE_URL}/${homeApiData.sectionImage.path}`
              : ""
          }')`,
        }}
      >
        <Container>
          <Row>
            <Col lg={12} sm={12} className="text-start">
              <h2 className="text-white">{homeApiData.section_title}</h2>
            </Col>
          </Row>
          <Swiper className="tickets_slider" {...carouselOptions}>
            {loading && <span className="loader" />}
            {error && (
              <div className="text-dark">{`Wystąpił problem z wczytaniem danych - ${error}`}</div>
            )}
            {!loading && apiFullData && apiFullData.length > 0 ? (
              apiFullData.map((post, index) => (
                /* index <= 9 && ( */
                <SwiperSlide key={index}>
                  <OurMatchesCard data={post} i={index} />
                </SwiperSlide>
              ))
            ) : (
              <span className="text-white">
                Brak rozegranych meczów dla obecnego sezonu
              </span>
            )}
            <Container fluid className="slider-pag wrap swiper-margin-top pe-0">
              <Row className="justify-content-start d-flex align-items-center">
                <Col lg={1} md={3} sm={2} className="col-3 button-prev-col">
                  {!loading && apiFullData && apiFullData.length > 0 && (
                    <div className="button-prev" id="our-matches-prev" />
                  )}
                </Col>

                <Col lg={1} md={3} sm={2} className="col-3 button-next-col">
                  {!loading && apiFullData && apiFullData.length > 0 && (
                    <div className="button-next" id="our-matches-next" />
                  )}
                </Col>

                <Col lg={10} md={6} sm={8} className="col-12 text-end">
                  <a href={homeApiData.section_btn_url}>
                    <div className="button-more common_btn red_bg">
                      <span>
                        {homeApiData.section_btn_text}
                        <div className="button-next d-inline-block ms-3" />
                      </span>
                    </div>
                  </a>
                </Col>
              </Row>
            </Container>
          </Swiper>
        </Container>
      </section>
    )
  );
};

export default OurMatches;
