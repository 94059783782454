import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, EffectFade, Autoplay } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/css/bundle";
import { PlayersCarouselData, PlayersCarouselPosts } from "@/data";
import PlayerCard from "@/components/cards/players-carousel";
import { Link } from "gatsby";
import api from "@/axios";

SwiperCore.use([Navigation, EffectFade, Autoplay]);

const PlayersCarousel = () => {
  const [apiData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/players/o/get`, {
          params: { actualSeasonPlayers: true },
        });
        setData(response.data.filter(v => v.team.id == 2));
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const { titleSection, titleButton } = PlayersCarouselData;
  const { posts } = PlayersCarouselPosts;
  const carouselOptions = {
    spaceBetween: 0,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
    autoHeight: true,
    navigation: {
      nextEl: "#players-carousel-next",
      prevEl: "#players-carousel-prev",
    },
    autoplay: {
      delay: 5000,
    },
  };

  return loading ? (
    <div className="d-flex justify-content-center align-items-center loader-players-carousel">
      <span className="loader"></span>
    </div>
  ) : (
    <section className="commonSection players-carousel pb-0 px-lg-5 px-md-0 px-sm-0">
      <Container>
        <Row className="justify-content-start d-flex align-items-center">
          <Col lg={6} md={6} sm={6} className="col-12 text-start">
            <h2 className="players-carousel-black">{titleSection}</h2>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={6}
            className="col-12 text-end mt-4 mt-sm-4 mt-md-0 mt-lg-0"
          >
            <Link to={`/szatnia`}>
              <div className="button-more common_btn red_bg players-carousel-black">
                <span>
                  {titleButton}
                  <div className="button-next d-inline-block ms-3"></div>
                </span>
              </div>
            </Link>
          </Col>
        </Row>
        {error && (
          <div className="text-dark">{`Wystąpił problem z wczytaniem zawodników - ${error}`}</div>
        )}
        {!loading && apiData && (
          <Swiper className="tickets_slider" {...carouselOptions}>
            {apiData.map((post, index) => (
              <SwiperSlide key={index}>
                <PlayerCard data={post} />
              </SwiperSlide>
            ))}
            <div
              className="button-prev carousel-absolute"
              id="players-carousel-prev"
            ></div>
            <div
              className="button-next carousel-absolute"
              id="players-carousel-next"
            ></div>
          </Swiper>
        )}
      </Container>
    </section>
  );
};

export default PlayersCarousel;
