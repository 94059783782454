import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const AchievementRow = ({ dataTimes, dataTitle, dataSubtitle, dataIcon }) => {
  return (
    <Row className="align-items-center">
      <Col
        lg={"auto"}
        className="pe-0 d-inline-flex justify-content-center d-none d-lg-block d-md-none d-sm-none"
      >
        <img className={"achievements-top-icon"} src={dataIcon} />
      </Col>
      <Col lg={"auto"} className="d-inline-flex justify-content-center">
        <span className="achievements-top-count ms-2">{dataTimes}</span>
        <span className="achievements-top-times">x</span>
      </Col>
      <Col lg={"auto"} className="col-12 ps-lg-3 ps-md-3 ps-sm-0">
        <Row className="achievements-top-title">{dataTitle}</Row>
        <Row className="achievements-top-years text-white">{dataSubtitle}</Row>
      </Col>
    </Row>
  );
};

export default AchievementRow;
