import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/css/bundle";
import { Link } from "gatsby";

const Newsletter = ({ homeApiData, loadingApi }) => {
  return loadingApi ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "0px" }}
    >
      <span className="loader" />
    </div>
  ) : (
    homeApiData && homeApiData.hide_section === false && (
      <section className="commonSection pt-sm-0 pt-md-5 pt-lg-5 newsletter-section">
        <Container className="newsletter">
          <Row>
            <Col
              lg={6}
              className="col-6 ps-0 ps-lg-5 d-none d-sm-none d-md-block d-lg-block"
            >
              <img
                src={
                  homeApiData.sectionImage
                    ? `${process.env.GATSBY_API_BASE_URL}/${homeApiData.sectionImage.path}`
                    : ""
                }
                className="img-fluid d-none d-sm-none d-md-block d-lg-block"
                alt=""
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="col-12 my-4">
              <h2 className="title">Zapisz się do jaga newsletter</h2>
              <h3 className="subtitle">Bądź na bieżąco!</h3>
              <form className="form-inline">
                <input
                  type="email"
                  className="form-control newsletter-email"
                  aria-describedby="newsletterHelp"
                  placeholder="Wpisz adres mailowy"
                />
                <button
                  type="submit"
                  className="common_btn jag_bg text-uppercase newsletter-submit"
                >
                  <span>Zapisz</span>
                </button>
              </form>
              <div
                id="newsletterHelp"
                className="form-text text-normal newsletter-rodo"
              >
                Potwierdzam, że zapoznałem się z informacją o{" "}
                <Link to="#">przetwarzaniu danych</Link> i chcę być na bieżąco
                przez Jagiellonię o wszelkich nowościach i akcjach promocyjnych.
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  );
};

export default Newsletter;
