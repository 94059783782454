import React, {useEffect, useState} from "react";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import Layout from "@/components/layout";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "../components/header-one";
import NewsCarousel from "../components/news-carousel";
import Footer from "@/components/footer";
import SliderTop from "@/components/slider-top";
import LeagueTable from "@/components/league-table";
import OurMatches from "@/components/our-matches";
import Tickets from "@/components/tickets";
import Calendar from "@/components/calendar";
import NewsBlock from "@/components/news-block";
import SponsorsCarousel from "@/components/sponsors-carousel";
import PlayersCarousel from "@/components/players-carousel";
import Tv from "@/components/tv";
import AdPreFooter from "@/components/ad-pre-footer";
import Newsletter from "@/components/newsletter";
import SponsorsListing from "@/components/sponsors-listing";
import api from "@/axios";


const Home = () => {
  const [apiHomepageBanner, setHomepageBanner] = useState(null);
  const [apiHomepageTicketSponsor, setHomepageTicketSponsor] = useState(null);
  const [apiHomepageSeasonTickets, setHomepageSeasonTickets] = useState(null);
  const [apiHomepageOurMatchesSection, setHomepageOurMatchesSection] = useState(
    null
  );
  const [apiHomepageCalendarSection, setHomepageCalendarSection] = useState(
    null
  );
  const [apiHomepageShopSection, setHomepageShopSection] = useState(null);
  const [
    apiHomepageSponsorCarouselSection,
    setHomepageSponsorCarouselSection,
  ] = useState(null);
  const [
    apiHomepageSponsorBannerSection,
    setHomepageSponsorBannerSection,
  ] = useState(null);
  const [apiHomepageNewsletterSection, setHomepageNewsletterSection] = useState(
    null
  );
  const [
    apiHomepageSponsorLogosSection,
    setHomepageSponsorLogosSection,
  ] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/settings/homepage`);
        const homepageBanner = response.data.find(
          v => v.name == "homepage_banner"
        );
        setHomepageBanner(homepageBanner);
        const homepageTicketSponsor = response.data.find(
          v => v.name == "tickets_section"
        );
        setHomepageTicketSponsor(homepageTicketSponsor);
        const homepageSeasonTicketsSection = response.data.find(
          v => v.name == "season_tickets_section"
        );
        setHomepageSeasonTickets(homepageSeasonTicketsSection);
        const homepageOurMatchesSection = response.data.find(
          v => v.name == "our_matches_section"
        );
        setHomepageOurMatchesSection(homepageOurMatchesSection);
        const homepageCalendarSection = response.data.find(
          v => v.name == "calendar_section"
        );
        setHomepageCalendarSection(homepageCalendarSection);
        const homepageShopSection = response.data.find(
          v => v.name == "fan_shop_section"
        );
        setHomepageShopSection(homepageShopSection);
        const homepageSponsorCarouselSection = response.data.find(
          v => v.name == "sponsor_sliders_section"
        );
        setHomepageSponsorCarouselSection(homepageSponsorCarouselSection);
        const homepageSponsorBannerSection = response.data.find(
          v => v.name == "sponsor_banner_section"
        );
        setHomepageSponsorBannerSection(homepageSponsorBannerSection);
        const homepageNewsletterSection = response.data.find(
          v => v.name == "newsletter_section"
        );
        setHomepageNewsletterSection(homepageNewsletterSection);
        const homepageSponsorLogosSection = response.data.find(
          v => v.name == "sponsor_logos_section"
        );
        setHomepageSponsorLogosSection(homepageSponsorLogosSection);

        setError(null);
      } catch (err) {
        setError(err.message);
        setHomepageBanner(null);
        setHomepageTicketSponsor(null);
        setHomepageSeasonTickets(null);
        setHomepageOurMatchesSection(null);
        setHomepageCalendarSection(null);
        setHomepageShopSection(null);
        setHomepageSponsorCarouselSection(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  // window.scrollTo(0, 0);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Oficjalna strona klubu" loading={loading}>
          <HeaderOneTop classes={"home"} />
          <HeaderOne />
          <SliderTop
            homeApiData={apiHomepageBanner && apiHomepageBanner.value}
            loadingApi={loading}
          />
          <NewsCarousel />
          <Tickets
            homeApiDataTickets={
              apiHomepageTicketSponsor && apiHomepageTicketSponsor.value
            }
            homeApiDataSeasonTickets={
              apiHomepageSeasonTickets && apiHomepageSeasonTickets.value
            }
            loadingApi={loading}
          />
          <LeagueTable />
          <AdPreFooter
            homeApiData={
              apiHomepageSponsorBannerSection &&
              apiHomepageSponsorBannerSection.value
            }
            loadingApi={loading}
          />
          <OurMatches
            homeApiData={
              apiHomepageOurMatchesSection && apiHomepageOurMatchesSection.value
            }
            loadingApi={loading}
          />
          <Calendar
            homeApiData={
              apiHomepageCalendarSection && apiHomepageCalendarSection.value
            }
            loadingApi={loading}
          />
          {/* <Shop
            homeApiData={apiHomepageShopSection && apiHomepageShopSection.value}
            loadingApi={loading}
          /> */}
          <NewsBlock />
          <SponsorsCarousel
            homeApiData={
              apiHomepageSponsorCarouselSection &&
              apiHomepageSponsorCarouselSection.value
            }
            loadingApi={loading}
          />
          <Tv />
          <Newsletter
            homeApiData={
              apiHomepageNewsletterSection && apiHomepageNewsletterSection.value
            }
            loadingApi={loading}
          />
          <SponsorsListing
            homeApiData={
              apiHomepageSponsorLogosSection &&
              apiHomepageSponsorLogosSection.value
            }
            loadingApi={loading}
          />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Home;
