import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/css/bundle";
import Countdown, { zeroPad } from "react-countdown";
import TicketsCard from "@/components/cards/tickets";
import { Link } from "gatsby";
import api from "@/axios";

const Tickets = ({
  homeApiDataTickets,
  homeApiDataSeasonTickets,
  loadingApi,
}) => {
  const [apiData, setData] = useState(null);
  const [apiFullData, setFullData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/stats/timetable`, {
          params: { mainTeam: true, venue: "home" },
        });
        let resData = response.data;
        resData = resData.filter(v => v.matchStatus === "Fixture");
        setData(resData[0]);
        setFullData(resData);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
        setFullData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const renderer = ({ days, hours, minutes, seconds }) => (
    <div>
      <div className="text-center mb-2">najbliższy mecz u siebie za:</div>
      <Row className="text-center countdown">
        <Col lg={2} md={2} sm={2} className="col-2">
          {zeroPad(days)}
          <div className="countdown-bottom">Dni</div>
        </Col>
        <Col className="col-1">:</Col>
        <Col lg={2} md={2} sm={2} className="col-2">
          {zeroPad(hours)}
          <div className="countdown-bottom">Godz.</div>
        </Col>
        <Col className="col-1">:</Col>
        <Col lg={2} md={2} sm={2} className="col-2">
          {zeroPad(minutes)}
          <div className="countdown-bottom">Min.</div>
        </Col>
        <Col className="col-1">:</Col>
        <Col lg={2} md={2} sm={2} className="col-2">
          {zeroPad(seconds)}
          <div className="countdown-bottom">Sek.</div>
        </Col>
      </Row>
    </div>
  );

  const carouselOptions = {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    autoHeight: true,
    navigation: {
      nextEl: "#tickets-next",
      prevEl: "#tickets-prev",
    },
    breakpoints: {
      0: {
        spaceBetween: 5,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 30,
        slidesPerView: 2.5,
        slidesPerGroup: 1,
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 3.5,
        slidesPerGroup: 1,
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 5.5,
        slidesPerGroup: 1,
      },
    },
  };

  return (
    <section className="commonSection">
      {loadingApi && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "1100px" }}
        >
          <span className="loader"></span>
        </div>
      )}
      {!loadingApi && (
        <>
          <Container>
            <Row>
              <Col lg={12} sm={12} className="text-start">
                <h2>BILETY</h2>
              </Col>
            </Row>
            {homeApiDataTickets && homeApiDataTickets.hide_section === false && (
              <Row
                className="tickets align-items-center p-sm-2"
                style={{
                  backgroundImage: `url('${
                    homeApiDataTickets.sponsorImage
                      ? `${process.env.GATSBY_API_BASE_URL}/${homeApiDataTickets.sponsorImage.path}`
                      : ""
                  }')`,
                }}
              >
                <Col lg={5} sm={12} className="text-start text-white mb-sm-2">
                  <h3>{homeApiDataTickets.sponsor_banner_title}</h3>
                </Col>
                <Col lg={1} sm={12} className="text-center mb-sm-2">
                  <a href={homeApiDataTickets.sponsorLogoUrl} target="_blank">
                    <img
                      src={
                        homeApiDataTickets.sponsorLogoImage
                          ? `${process.env.GATSBY_API_BASE_URL}/${homeApiDataTickets.sponsorLogoImage.path}`
                          : ""
                      }
                    />
                  </a>
                </Col>
                <Col lg={3} sm={12} className="text-end offset-lg-2 mb-sm-2">
                  {loading && <span className="loader"></span>}
                  {error && (
                    <div className="text-dark">{`Wystąpił problem z wczytaniem daty - ${error}`}</div>
                  )}
                  {!loading && apiData && (
                    <Countdown
                      className="countdown"
                      date={apiData.startDate.replace(/\s/, "T")}
                      renderer={renderer}
                    />
                  )}
                </Col>
              </Row>
            )}
          </Container>
          <Container className="mt-4 px-0">
            <Swiper className="tickets_slider" {...carouselOptions}>
              {loading && <span className="loader"></span>}
              {error && (
                <div className="text-dark">{`Wystąpił problem z wczytaniem daty - ${error}`}</div>
              )}
              {!loading &&
                apiFullData && apiFullData.map((post, index) => (
                    <SwiperSlide key={index}>
                        <TicketsCard data={post} i={index}/>
                    </SwiperSlide>
                  ))}

              <Container
                fluid
                className="slider-pag wrap swiper-margin-top pe-0"
              >
                <Row className="justify-content-start d-flex align-items-center">
                  <Col lg={1} md={3} sm={2} className="col-3 button-prev-col">
                    <div className="button-prev" id="tickets-prev"></div>
                  </Col>
                  <Col lg={1} md={3} sm={2} className="col-3 button-next-col">
                    <div className="button-next" id="tickets-next"></div>
                  </Col>
                  <Col lg={10} md={6} sm={8} className="col-12 text-end">
                    <Link to="/rozgrywki">
                      <div className="button-more common_btn red_bg">
                        <span>
                          Terminarz
                          <div className="button-next d-inline-block ms-3"></div>
                        </span>
                      </div>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Swiper>
          </Container>
          {homeApiDataSeasonTickets &&
            homeApiDataSeasonTickets.hide_section === false && (
              <Container className="mt-5">
                <Row
                  className="pass align-items-center"
                  style={{
                    backgroundImage: `url('${
                      homeApiDataSeasonTickets.seasonImage
                        ? `${process.env.GATSBY_API_BASE_URL}/${homeApiDataSeasonTickets.seasonImage.path}`
                        : ""
                    }')`,
                  }}
                >
                  <Col lg={5} sm={12} className="mb-sm-2">
                    <h3 className="text-center text-sm-center text-md-center text-lg-start">
                      {homeApiDataSeasonTickets.season_banner_title}
                    </h3>
                  </Col>
                  <Col
                    lg={7}
                    sm={12}
                    className="text-center text-sm-center text-md-center text-lg-end mb-2 mb-sm-2 mb-md-0 mb-lg-0 mt-4 mt-sm-4 mt-md-4 mt-lg-0"
                  >
                    <a href={homeApiDataSeasonTickets.season_btn_yellow_url}>
                      <button className="btn__effect profile-button profile-button-yellow me-1 me-sm-1 me-md-1 me-lg-5">
                        {homeApiDataSeasonTickets.season_btn_yellow_text}
                      </button>
                    </a>
                    <a href={homeApiDataSeasonTickets.season_btn_red_url}>
                      <button className="btn__effect profile-button profile-button-red">
                        {homeApiDataSeasonTickets.season_btn_red_text}
                      </button>
                    </a>
                  </Col>
                </Row>
              </Container>
            )}
        </>
      )}
    </section>
  );
};

export default Tickets;
