import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Spin } from "antd";
import "swiper/css/bundle";
import Moment from "moment";
import api from "@/axios";
import "moment/locale/pl";
import ReactTooltip from "react-tooltip";

import ballIcon from "@/images/icons/ball.svg";
import birthdayIcon from "@/images/icons/birthday.svg";
import eventIcon from "@/images/icons/event.svg";

const Calendar = ({ homeApiData, loadingApi }) => {
  const [currentDate, setCurrentDate] = useState(Moment());
  const [calendarData, setCalendarData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const currentDay = Moment().format("DD");
  const currentMonth = Moment().format("MM");
  const arrDaysOfMonth = Array.from(
    Array.from(Array(currentDate.daysInMonth()), (_, i) => i + 1)
  );
  const nextMonthArrow = () => {
    setCurrentDate(currentDate.clone().add(1, "month"));
  };
  const prevMonthArrow = () => {
    setCurrentDate(currentDate.clone().subtract(1, "month"));
  };
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [arrDaysOfMonth]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/calendar/get`, {
          params: { month: currentDate.format("YYYY-MM"), mainTeam: true },
        });

        let { data } = response;
        setCalendarData(data.days);

        setError(null);
      } catch (err) {
        setError(err.message);
        setCalendarData(null);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [currentDate]);

  return loadingApi ? (
    <div className="d-flex justify-content-center align-items-center loader-calendar">
      <span className="loader"></span>
    </div>
  ) : (
    <>
      {homeApiData && homeApiData.hide_section === false && (
        <section className="commonSection">
          <Container>
            <Row>
              <Col lg={12} sm={12} className="text-start">
                <h2>KALENDARZ</h2>
              </Col>
            </Row>
            <Row className="mb-2 mb-sm-2 mb-md-2 mb-lg-5">
              <Col
                lg={"auto"}
                md={12}
                sm={12}
                className="col-12 text-center calendar-nav-col"
              >
                <i
                  onClick={() => prevMonthArrow()}
                  className="calendar-nav-arrows"
                >
                  {"<"}
                </i>
                <span className="calendar-month-name text-uppercase">
                  {Moment(
                    currentDate.month() + 1 + "-" + currentDate.year(),
                    "MM-YYYY"
                  ).format("YYYY MMMM")}
                </span>
                <i
                  onClick={() => nextMonthArrow()}
                  className="calendar-nav-arrows"
                >
                  {">"}
                </i>
              </Col>
              <Col
                lg={"auto"}
                md={12}
                sm={12}
                className="d-flex align-items-center justify-content-center ms-auto text-center calendar-agenda mt-3 mt-sm-3 mt-md-3 mt-lg-0 gap-2"
              >
                <span>
                  <img src={ballIcon} className="me-2 calendar-red-icon" />{" "}
                  dzień meczowy
                </span>
                <span>
                  <img src={birthdayIcon} className="me-2 ms-4" /> urodziny
                </span>
                <span>
                  <img src={eventIcon} className="me-2 ms-4" /> wydarzenia
                </span>
              </Col>
            </Row>
            <Row className="justify-content-between calendar-row">
              {loading && (
                <Row className="py-5">
                  <span className="loader"></span>
                </Row>
              )}

              {!loading &&
                arrDaysOfMonth.map((day, index) => {
                  let find = calendarData.find(v => v.dayNum == day);
                  let objects = null;

                  let icons = [];

                  if (find && find.data.length > 0) {
                    objects = find.data;

                    for (var c = 0; c < objects.length; c++) {
                      let obj = objects[c];

                      if (obj.type == "matchday" && !icons.includes("matchday"))
                        icons.push("matchday");
                      if (obj.type == "event" && !icons.includes("event"))
                        icons.push("event");
                      if (obj.type == "birthday" && !icons.includes("birthday"))
                        icons.push("birthday");
                    }
                  }

                  return (
                    <Col
                      xl={"auto"}
                      lg={3}
                      md={3}
                      sm={12}
                      key={index}
                      data-for="content"
                      data-tip={
                        objects
                          ? JSON.stringify({ objects: objects, day: day })
                          : null
                      }
                      className={`col-12 px-0 justify-content-center text-center ${
                        day == currentDay &&
                        currentDate.month() + 1 == currentMonth &&
                        "calendar-today-col"
                      }`}
                    >
                      <Row>
                        <Col
                          className=""
                          style={{ textTransform: "capitalize" }}
                        >
                          {Moment(
                            Moment(currentDate).format(
                              "YYYY-MM-" +
                                (day.toString().length == 1 ? "0" + day : day)
                            )
                          ).format("ddd") + "."}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          className={`fw-bold p-0 p-sm-0 p-md-auto p-lg-auto ${
                            day == currentDay && "calendar-today"
                          }`}
                        >
                          {day}
                        </Col>
                      </Row>
                      <Row>
                        {icons.includes("matchday") && (
                          <Col className="p-0" style={{ flexBasis: "auto" }}>
                            <img src={ballIcon} className="calendar-red-icon" />
                          </Col>
                        )}

                        {icons.includes("event") && (
                          <Col className="p-0" style={{ flexBasis: "auto" }}>
                            <img src={eventIcon} />
                          </Col>
                        )}

                        {icons.includes("birthday") && (
                          <Col className="p-0" style={{ flexBasis: "auto" }}>
                            <img src={birthdayIcon} />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  );
                })}

              <ReactTooltip
                type="light"
                effect="solid"
                id="content"
                multiline={true}
                getContent={dataTip => {
                  if (dataTip) {
                    dataTip = JSON.parse(dataTip);

                    return (
                      <div className="calendar-tooltip-box text-start">
                        <h6 className="fw-bold text-capitalize">
                          {Moment(
                            Moment(currentDate).format(
                              "YYYY-MM-" +
                                (dataTip.day.toString().length == 1
                                  ? "0" + dataTip.day
                                  : dataTip.day)
                            )
                          ).format("dddd DD.MM.YYYY")}
                        </h6>
                        <hr />
                        {dataTip.objects &&
                          dataTip.objects.length > 0 &&
                          dataTip.objects.map((e, i) => {
                            let selectedIcon = ballIcon;

                            if (e.type == "event") selectedIcon = eventIcon;
                            else if (e.type == "birthday")
                              selectedIcon = birthdayIcon;

                            return (
                              <Row
                                key={i}
                                className="justify-content-center align-items-center p-2"
                              >
                                <Col className={`col-2 text-center`}>
                                  <img
                                    src={selectedIcon}
                                    className={
                                      e.type == "matchday"
                                        ? "calendar-red-icon"
                                        : ""
                                    }
                                  />
                                </Col>
                                <Col className="col-8">
                                  <Row className="calendar-tooltip-details flex-column">
                                    {e.beforeTitle && (
                                      <Col className="col-12">
                                        <small>{e.beforeTitle}</small>
                                      </Col>
                                    )}
                                    {e.title && (
                                      <Col className="col-12 fw-bold">
                                        {e.title}
                                      </Col>
                                    )}
                                    {e.afterTitle && (
                                      <Col className="col-12">
                                        <small>{e.afterTitle}</small>
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                                <Col className="col-2 fw-bold">
                                  {e.rightText ? e.rightText : ""}
                                </Col>
                              </Row>
                            );
                          })}
                      </div>
                    );
                  } else return null;
                }}
              />
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default Calendar;
