import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import SocialIcons from "@/components/social-icons";
import AchievementRow from "@/components/achievements";
import api from "@/axios";

import sliderTop01 from "@/images/bg/home/top.jpg";

import Medal1Icon from "@/images/icons/medal-1.svg";
import Medal2Icon from "@/images/icons/medal-2.svg";
import Medal3Icon from "@/images/icons/medal-3.svg";
import CupIcon from "@/images/icons/cup.svg";

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderTop = ({ homeApiData, loadingApi }) => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    effect: "fade",
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    // autoplay: {
    //   delay: 5000,
    // },
  };

  const [apiSocialMedia, setSocialMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/settings/homepage`);
        const socialMedia = response.data.find(v => v.name == "social_media");
        setSocialMedia(socialMedia);

        setError(null);
      } catch (err) {
        setError(err.message);
        setSocialMedia(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  function youtubeIdParser(url) {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  return (
    <section className="main-slider slider-home">
      {loadingApi && (
        <Swiper {...mainSlideOptions}>
          <SwiperSlide>
            <span className="loader" />
          </SwiperSlide>
        </Swiper>
      )}
      {!loadingApi && (
        <Swiper {...mainSlideOptions}>
          <SwiperSlide>
            {homeApiData.bannerType == "image" && (
              <div
                className="image-layer"
                style={{
                  backgroundImage: `url('${
                    homeApiData.bannerImage
                      ? `${process.env.GATSBY_API_BASE_URL}/${homeApiData.bannerImage.path}`
                      : sliderTop01
                  }')`,
                }}
              />
            )}
            {homeApiData.bannerType == "movie" && (
              <div className="video-container">
                <iframe
                  src={`https://www.youtube.com/embed/${youtubeIdParser(
                    homeApiData.url
                  )}? &autoplay=1&mute=1&playlist=${youtubeIdParser(
                    homeApiData.url
                  )}&loop=1`}
                />
              </div>
            )}
            <Container className="w-auto achievements-top-container animated fadeInUp">
              <Row>
                <Col
                  lg={12}
                  className="text-start d-none d-sm-none d-md-block d-lg-block"
                >
                  <Row className="achievements-top">
                    <Col lg="auto" md={12} sm={12} className="col-12 px-4">
                      <AchievementRow
                        dataTimes={homeApiData.performance_number_1}
                        dataTitle={homeApiData.performance_title_1}
                        dataSubtitle={homeApiData.performance_subtitle_1}
                        dataIcon={Medal1Icon}
                      />
                    </Col>
                    <Col lg="auto" md={12} sm={12} className="col-12 px-4">
                      <AchievementRow
                        dataTimes={homeApiData.performance_number_2}
                        dataTitle={homeApiData.performance_title_2}
                        dataSubtitle={homeApiData.performance_subtitle_2}
                        dataIcon={Medal2Icon}
                      />
                    </Col>
                    <Col lg="auto" md={12} sm={12} className="col-12 px-4">
                      <AchievementRow
                        dataTimes={homeApiData.performance_number_3}
                        dataTitle={homeApiData.performance_title_3}
                        dataSubtitle={homeApiData.performance_subtitle_3}
                        dataIcon={Medal3Icon}
                      />
                    </Col>
                    <Col lg="auto" md={12} sm={12} className="col-12 px-4">
                      <AchievementRow
                        dataTimes={homeApiData.performance_number_4}
                        dataTitle={homeApiData.performance_title_4}
                        dataSubtitle={homeApiData.performance_subtitle_4}
                        dataIcon={CupIcon}
                      />
                    </Col>
                    <Col lg="auto" md={12} sm={12} className="col-12 px-4">
                      <AchievementRow
                        dataTimes={homeApiData.performance_number_5}
                        dataTitle={homeApiData.performance_title_5}
                        dataSubtitle={homeApiData.performance_subtitle_5}
                        dataIcon={CupIcon}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        </Swiper>
      )}
      {!loading && apiSocialMedia && (
        <SocialIcons SocialMediaApiData={apiSocialMedia.value} />
      )}
    </section>
  );
};

export default SliderTop;
